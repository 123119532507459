import React from "react"
import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
  html, body {
    font-size: 16px;
    margin: 0;
  }
`

export default function Layout({ children }) {
  return (
    <>
      <GlobalStyle />
      {children}
    </>
  )
}