import React from 'react';
import { ThemeProvider } from "styled-components";

import { Toast } from 'src/components/Toast';
import { theme } from "src/assets/theme";
import Layout from 'src/components/layout';

// Wraps every page in a component
export const wrapPageElement = ({ element }) => {
  return <Layout>{element}</Layout>
}

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>
    <Toast />
    {element}
  </ThemeProvider>
)