import { DefaultTheme } from "styled-components";
import "styled-components";

declare module "styled-components" {
  export interface DefaultTheme {
    colors: {
      [key: string]: string;
      white: string;
      black: string;
      lightBrown: string;
      paleBrown: string;
      errorRed: string;
    };
    breakpoints: {
      xs: string;
      sm: string;
      md: string;
      lg: string;
    };
    fonts: {
      caroline: string;
      ebrima: string;
      ebrimaBold: string;
    };
    fontSizes: {
      xs: string;
      sm: string;
      md: string;
      lg: string;
      xl: string;
      xl2: string;
      xxl: string;
      xxxl: string;
    };
    border: {
      "10px": string;
    };
    spacing: {
      "50px": string;
      md: string;
      md2: string;
      lg: string;
    };
    zIndex: {
      [key: string]: number;
      "1st": number;
      "2nd": number;
      "3rd": number;
      "4th": number;
      "5th": number;
      "6th": number;
      "7th": number;
      "8th": number;
      "9th": number;
      "10th": number;
    };
  }
}

export const theme: DefaultTheme = {
  colors: {
    white: "#FFFFFF",
    black: "#000000",
    lightBrown: "#FAF9F8",
    paleBrown: "#6C6C6C",
    errorRed: "#E15A5A",
  },
  breakpoints: {
    xs: "500px",
    sm: "768px",
    md: "992px",
    lg: "1200px",
  },
  fonts: {
    caroline: 'Caroline',
    ebrima: "Ebrima",
    ebrimaBold: "Ebrima-Bold",
  },
  fontSizes: {
    xs: "0.75rem", //12px
    sm: "1.125rem", //18px
    md: "1.25rem", //20px
    lg: "1.5rem", //24px
    xl: "1.875rem", //30px
    xl2: "2.25rem", //36px
    xxl: "4rem", //64px
    xxxl: "9rem", //144px
  },
  border: {
    "10px": "0.625rem",
  },
  spacing: {
    "50px": "3.125rem",
    md: "1.5rem",
    md2: "2rem",
    lg: "3.5rem",
  },
  zIndex: {
    "1st": 999999999,
    "2nd": 9999999,
    "3rd": 999999,
    "4th": 99999,
    "5th": 99998,
    "6th": 9999,
    "7th": 10,
    "8th": 5,
    "9th": 1,
    "10th": 0,
  },
};
